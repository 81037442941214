.venue-badges-hgroup {
  flex-direction: row;
  gap: 1rem;

  p {
    color: var(--color-reco-blue);
  }
}

.venue-badges {
  display: flex;
  margin-right: -1rem;
  flex-wrap: wrap;
  row-gap: 1rem;

  @media screen and (min-width: $breakpoint-portrait-tablet) {
    margin-right: -1.5rem;
    row-gap: 1.5rem;
  }
}

.venue-badges-list {
  --items: 1;
  display: flex;
  flex-direction: row-reverse;
  margin: 0;
  padding: 0;
  list-style: none;
  flex: 1 1 auto;
  min-width: calc(5rem + (var(--items) - 1) * 1rem);
  max-width: calc(var(--items) * 6rem);

  @media screen and (min-width: $breakpoint-portrait-tablet) {
    min-width: calc(7.5rem + (var(--items) - 1) * 1.5rem);
    max-width: calc(var(--items) * 9rem);
  }
}

.venue-badges-item {
  flex: 0 1 6rem;
  height: 5rem;
  width: 0;

  @media screen and (min-width: $breakpoint-portrait-tablet) {
    flex-basis: 9rem;
    height: 7.5rem;
  }

  &:first-child {
    flex-shrink: 0;
  }

  img {
    filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.16));
    height: 100%;
  }
}
