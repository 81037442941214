.venue-header {
  grid-area: main;
  position: relative;
}

.venue-header-top {
  display: flex;
  gap: 1.5rem;
  padding-top: 1.5rem;

  @media screen and (min-width: $breakpoint-portrait-tablet) {
    padding-top: 0;
  }
}

.venue-header-top-image {
  border-radius: 0.25rem;
  border: 2px solid white;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16);
  object-fit: contain;
  background-color: white;
  position: absolute;
  transform: translateY(calc(-100% - 1rem));
  height: 5rem;
  aspect-ratio: 1.5;

  @media screen and (min-width: $breakpoint-portrait-tablet) {
    position: static;
    transform: none;
    height: 6rem;
  }
}

.venue-header-top-title {
  .cooltip-wrapper {
    display: inline-flex;
    align-items: center;
    vertical-align: middle;
  }
}

.venue-header-top-heading {
  display: inline;
  vertical-align: middle;
}

.venue-header-top-icon {
  height: 2rem;
  width: 2rem;
  line-height: 2rem;
  text-align: center;
  font-size: 1rem;

  @media screen and (min-width: $breakpoint-portrait-tablet) {
    height: 2.5rem;
    width: 2.5rem;
    line-height: 2.5rem;
    font-size: 1.5rem;
  }
}

.venue-header-top-details {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  flex: 1 1 auto;
  align-self: center;

  @media screen and (min-width: $breakpoint-portrait-tablet) {
    gap: 0.25rem;
  }
}

.venue-header-top-actions {
  display: flex;
  flex-direction: column;
  margin: 0 -0.75rem;

  @media screen and (min-width: $breakpoint-portrait-tablet) {
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;
  }

  .felt-btn {
    padding: 0.5rem 0.75rem;
    gap: 0.25rem;
  }

  .felt-icon {
    align-self: flex-start;

    &:first-child {
      margin-left: -0.25rem;
    }
  }
}

.venue-header-badge {
  flex: 0 0 9rem;
  height: 5.5rem;
}

.venue-header-badge-stack {
  display: flex;
  flex: 0 0 calc(5rem + 8px);
  flex-direction: row-reverse;
}

.venue-header-badge-list {
  display: flex;
  flex-direction: row-reverse;
  margin: 0;
  padding: 0;
  list-style: none;
}

.venue-header-top-ten-badge {
  height: 5.5rem;
}

.venue-header-badge-item {
  flex: 0 1 5rem;
  height: 5rem;
  width: 0;

  &:first-child {
    flex-shrink: 0;
  }

  img {
    filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.16));
    height: 100%;
  }
}

.venue-header-badge-shadow {
  display: block;
  height: 100%;
  aspect-ratio: 1;
  border-radius: 999px;
  background-color: white;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16);
}

.venue-header-reviews,
.venue-header-reviews-pair {
  display: flex;
  flex-wrap: wrap;
  gap: 2px;
  background: linear-gradient(var(--color-grey-300), var(--color-grey-300)) center /
      calc(100% - 2rem) calc(100% - 2rem) no-repeat,
    var(--color-grey-200);
  border-radius: 1rem;
}

.venue-header-reviews {
  border-radius: 0.5rem;
}

.venue-header-reviews-pair {
  flex: 2 1 calc(24rem + 2px);

  @media screen and (min-width: $breakpoint-portrait-tablet) {
    flex-basis: calc(28rem + 2px);
  }
}

.venue-header-reviews-item {
  --rating-size: 1rem;

  flex: 1 1 12rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: padding-box var(--color-grey-200);
  border-radius: 1rem;

  @media screen and (min-width: $breakpoint-portrait-tablet) {
    --rating-size: 1.5rem;

    flex: 1 1 14rem;
  }
}

.venue-header-reviews-item-inner {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: center;
  padding: 1rem;
}

.venue-header-reviews-item-content {
  display: flex;
  gap: 0.5rem;
  align-items: center;

  b,
  strong {
    font-weight: 500;
    line-height: 1.5rem;
  }

  strong {
    font-size: 1.5rem;
  }
}

.venue-header-details {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  @media screen and (min-width: $breakpoint-portrait-tablet) {
    flex-direction: row;
    gap: 4.5rem;
  }
}

.venue-header-details-actions {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  @media screen and (min-width: $breakpoint-portrait-tablet) {
    flex: 0 0 16rem;
  }
}

.venue-header-details-content {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  flex: 1 1 auto;
}
